
function extractUserTokenFromCookies(cookiesString,cookieName) {
    const cookies = cookiesString.split('; ');

    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name.trim() === cookieName) {
        return value;
        }
    }

    return null; // Return null if userToken is not found in cookies
}



  export const anonymousAuth = async function (req, res) {
    const cookies = req.headers.cookie || '';
    let userToken = await extractUserTokenFromCookies(cookies, 'userToken');
  
    if (userToken == 'undefined' || userToken == '' || userToken == null) {
      userToken = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/auth/anonymous`, {
        method: 'post',
      }).then((res) => res?.json().then((data) => data.token));
  
      res?.cookie('userToken', userToken, {
        secure: process.env.APP_ENV === 'production',
        maxAge: 86400 * 1000,
      });
    }
    return userToken
  }

  export const validateIC = function (ic){
    let isValid = true
    //check ic contains non digits
    if(/\D/.test(ic)){
      isValid = false
    }
    return isValid
  }
  export const validatePassport = function (passport){
    let isValid = true
    //check passport length
    if (passport.length < 6){
      isValid = false
    }
    //check for special character
    const specialCharRegex = /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\]/
    const containsSpecialChar = specialCharRegex.test(passport)
    if(containsSpecialChar){
      isValid = false
    }
    return isValid
  }
  export const validateDob = function (date){
    var dob = new Date(date);
    var today = new Date();
    var age = today.getFullYear() - dob.getFullYear();

    // Check if the birthday has occurred this year already
    if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
        age--;
    }
    return age > 18
  }
  export const validatePhoneNumber = function (phoneNumber){
    let isValid = true
    //check length
    if(![9, 10].includes(phoneNumber.length)){
      isValid = false
    }
    //check for non digits
    if(/\D/.test(phoneNumber)){
      isValid = false
    }
    return isValid
  }

  export const validateName = (name) =>{
    let isValid = true

    //check for whitespaces
    if(!name?.includes(' ')){
      isValid = 'fullNameErrorSpaces'
      return isValid
    }

    //check for special characters
    const specialCharRegex = /[!@#$%^&*()\_=+[\]{}|;:'",.<>?\\]/
    const containsSpecialChar = specialCharRegex.test(name)
    if(containsSpecialChar){
      isValid = 'fullNameErrorSpecial'
    }
    return isValid
  }
  
  