import { useState, useEffect, useRef } from 'react'

import 'react-phone-number-input/style.css';
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';

export default function PhoneNumberInput({mobile, setMobile,defaultMobile, disabled}) {
    const [value, setValue] = useState('')
    const [country, setCountry] = useState('')

    useEffect(()=>{
      if(defaultMobile){
        setValue(`+${defaultMobile.countryCode}${defaultMobile.phoneNumber}`)
      }
    }, [defaultMobile])

    useEffect(()=>{
      if(value){
        setMobile({
          ...mobile,
          countryCode:parsePhoneNumber(value)?.countryCallingCode,
          phoneNumber:parsePhoneNumber(value)?.nationalNumber,

        })
      }
    }, [value, country])
    return (
        <PhoneInput
          placeholder="Enter phone number"
          international
          defaultCountry='MY'
          disabled={disabled}
          onCountryChange={setCountry}
          value={value}
          onChange={setValue}/>
      )
}